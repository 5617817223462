<template>
  <com-message-box
    class="transition"
    :visible.sync="visible"
    @submit="submit"
    @cancel="$emit('update:visible', false)"
  >
    <div class="score-container">
      <div class="label">总体评价</div>
      <div class="main" @mouseout="num = oldNum">
        <span
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @mouseover="over(index + 1)"
          @click="oldNum = num"
        >
          <span v-if="item.bool"><img :src="item.y" /></span>
          <span v-if="!item.bool"><img :src="item.n" /></span>
        </span>
      </div>
      <div class="txt" v-if="num > 0">{{ list[num - 1].t }}</div>
      <div class="txt" v-else>点击笑脸给舞曲打分</div>
    </div>
  </com-message-box>
</template>
<script>
import comMessageBox from "@/components/com/com-message-box";
export default {
  components: {
    comMessageBox,
  },
  data() {
    return {
      num: 0,
      oldNum: 0,
      list: [
        {
          y: require("../../assets/pj/1.png"),
          n: require("../../assets/pj/6.png"),
          bool: false,
          t: "极差",
        },
        {
          y: require("../../assets/pj/2.png"),
          n: require("../../assets/pj/7.png"),
          bool: false,
          t: "失望",
        },
        {
          y: require("../../assets/pj/3.png"),
          n: require("../../assets/pj/8.png"),
          bool: false,
          t: "一般",
        },
        {
          y: require("../../assets/pj/4.png"),
          n: require("../../assets/pj/9.png"),
          bool: false,
          t: "满意",
        },
        {
          y: require("../../assets/pj/5.png"),
          n: require("../../assets/pj/10.png"),
          bool: false,
          t: "惊喜",
        },
      ],
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    num(i) {
      this.list.forEach((item, index) => {
        item.bool = false;
        i >= index + 1 && (item.bool = true);
      });
    },
  },
  methods: {
    over(i) {
      this.num = i;
    },
    submit() {
      this.$emit("update:visible", false);
      this.$emit("submit", this.oldNum);
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/animation";
.transition {
  animation: opacity 0.3s ease-out;
}
.score-container {
  display: flex;
  align-items: center;
  transition: all 5s;
}

.label {
  margin-right: 10px;
  color: #333333;
  font-size: 16px;
}
.item {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0 9px;
  overflow: hidden;
  > span {
    display: flex;
  }
  img {
    width: 100%;
  }
}
.txt {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a0a2a6;
}
</style>
