<template>
  <div class="single-detail-container">
    <div class="left">
      <com-introduction v-if="detail" :data="detail"></com-introduction>
    </div>
    <div class="right">
      <com-top-info v-if="detail" :data="infoData" :songs="true">
        <template slot="btns">
          <com-button :icon="require('../assets/play.png')" @click="playAll"
            >全部播放</com-button
          >
          <com-button
            :icon="require('../assets/pj.png')"
            type="shallow"
            @click="scoreShow = true"
            >评价</com-button
          >
        </template>
      </com-top-info>

      <table-single
        v-loading="loading"
        :total="total"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @currentPageChange="pageChang"
        :list="list"
      >
      </table-single>

      <div ref="scoreList">
        <com-score-list
          :list="scoreList"
          :total="scoreListTotal"
          @load="scoreListLoad"
        ></com-score-list>
      </div>
      <com-score
        v-if="scoreShow"
        :visible.sync="scoreShow"
        @submit="scoreSubmit($event)"
      ></com-score>
    </div>
  </div>
</template>
<script>
import comIntroduction from "@/components/com/com-introduction.vue";
import comTopInfo from "@/components/com/com-top-info.vue";
import tableSingle from "@/components/table/table-single.vue";
import comScoreList from "@/components/com/com-score-list";
import comScore from "@/components/com/com-score";
import comButton from "@/components/com/com-button";
import { Single } from "@/class/music";
import { score } from "@/mixin/score";
export default {
  mixins: [score],
  components: {
    comIntroduction,
    comTopInfo,
    tableSingle,
    comScoreList,
    comScore,
    comButton,
  },
  data() {
    return {
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      id: "",
      detail: null,
    };
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(v) {
        this.id = v;
      },
    },
  },
  computed: {
    name() {
      return this.$route.query.name;
    },
    infoData() {
      let { detail } = this;
      return {
        name: detail.name,
        user: detail.userName,
        type: detail.listTypeName,
        times: detail.times,
        releaseTime: detail.releaseTime,
      };
    },
    userId() {
      return this.$store.state.user.userInfo?.id;
    },
  },
  methods: {
    sendGetList() {
      this.loading = true;
      this.$api
        .bySongsGetSingleList({
          size: this.pageSize,
          current: this.currentPage,
          listId: this.id,
        })
        .then((resolve) => {
          this.loading = false;
          this.total = resolve.data.total;
          this.list = resolve.data.records.map((item) => {
            item.name = item.musicName;
            item.id = item.musicId;
            item.playListId = this.id;
            item.cover = item.musicCover;
            return new Single(item);
          });
        })
        .catch(() => (this.loading = false));
    },
    sendGetDetail() {
      console.log(this.name);
      this.$api.songsDetail({ name: this.name }).then((resolve) => {
        this.detail = resolve.data;
      });
    },
    playAll() {
      this.$store.commit("ADD_PLAYLIST", this.list);
    },
    scoreListLoad() {
      this.detail && this.detail.id && this.scoreSongsList(this.detail.id);
    },
    scoreSubmit($event) {
      if (!this.userId) {
        this.$message.info("请先登录");
        return;
      }
      this.$api
        .getIsComment({ listId: this.id, userId: this.userId })
        .then((resolve) => {
          // console.log($event,resolve)
          let params = {
            score: $event,
            listId: ~~this.id,
            ownerId: this.detail.userId,
            ownerName: this.detail.userName,
            listName: this.detail.listName,
            id: resolve.data ? resolve.data.id : null,
          };
          // if(resolve.data)params.id = resolve.data.id
          this.scoreSongsSubmit(params);
          // resolve.data === null
          //   ? this.scoreSongsSubmit($event, this.id, this.detail.userId)
          //   : this.scoreSongsSubmit(
          //       $event,
          //       this.id,
          //       this.detail.userId,
          //       resolve.data.id
          //     );
        });
    },
    pageChang(currentPage) {
      this.currentPage = currentPage;
      this.sendGetList();
    },
  },
  mounted() {
    document.addEventListener("scroll", () => {
      if (this.$refs.scoreList) {
        // console.log(this.$refs.scoreList.offsetHeight,this.$refs.scoreList.offsetTop)
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;

        if (scrollTop + windowHeight + 180 > scrollHeight) {
          //写后台加载数据的函数
          this.scoreListLoad();
          // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
        }
      }
    });

    this.sendGetList();
    this.sendGetDetail();
    this.scoreSongsList(this.id);
  },
};
</script>
<style scoped>
.single-detail-container {
  display: flex;
  width: 100%;
  margin-top: 68px;
}
.single-detail-container .right {
  flex-grow: 1;
  width: 0;
  margin-left: 100px;
}
</style>
