<template>
  <div class="introduction">
    <div class="image"><img :src="data.cover" /></div>
    <div class="t">简介</div>
    <div class="c" v-html="br(data.introduce)"></div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },
  methods: {
    br(v) {
      return v ? v.replace(/\n/g, "<br>") : "";
    },
  },
};
</script>
<style scoped lang="scss">
$width: 350px;
.introduction {
  width: $width;
}
.image {
  width: $width;
  img {
    width: 100%;
  }
}
.t {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 40px 0 29px 0;
}
.c {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #696b6f;
}
</style>
