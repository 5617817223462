<template>
  <div class="mask" v-if="visible">
    <div class="box">
      <div class="head">
        舞曲评价<span @click="$emit('cancel')"
          ><i class="el-icon-close"></i
        ></span>
      </div>
      <div class="message-box-main">
        <slot></slot>
      </div>
      <div class="btns">
        <com-button class="btn" type="white" @click="$emit('cancel')"
          >取消</com-button
        >
        <com-button class="btn" @click="$emit('submit')">提交</com-button>
      </div>
    </div>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
export default {
  components: {
    comButton,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped lang="scss">
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
}
.box {
  width: 612px;
  //height: 500px;
  background: #fff;
  padding: 0 41px;
  box-sizing: border-box;
  .head {
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #eeeeee;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    span {
      display: inline-block;
      height: 60px;
      line-height: 60px;
      float: right;
      margin-right: 10px;
      font-size: 16px;
      cursor: pointer;
      margin-right: -20px;
      i {
        color: #333333;
      }
    }
  }
}
.message-box-main {
  //padding: 16px;
  margin: 50px 16px;
}
.btns {
  display: flex;
  justify-content: center;
  margin-bottom: 52px;
  .btn {
    margin-right: 10px;
  }
}
</style>
