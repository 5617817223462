/*
 * 评论
 * */
export const score = {
  data() {
    return {
      scoreList: [],
      scoreShow: false,
      mixinScoreSize: 10,
      mixinScoreCurrent: 0,
      lock: false,
      mixinScroll: null,
      scoreListTotal: 0,
    };
  },
  methods: {
    scoreSongsSubmit(params) {
      // id, ownerId, scoreId
      if (params.score < 1) {
        this.$message.error("请先选择分数");
        return;
      }

      // let d = { score: score, listId: id, ownerId };
      // scoreId && (d.id = scoreId);
      scoreSubmit.call(this, params).then(() => {
        this.mixinResetScoreData();
        this.scoreSongsList(params.listId);
      });
    },
    scoreSingleSubmit(params) {
      if (params.score < 1) {
        this.$message.error("请先选择分数");
        return;
      }

      // let d = { score: score, musicId: id, ownerId };
      // scoreId && (d.id = scoreId);
      scoreSubmit.call(this, params).then(() => {
        this.mixinResetScoreData();
        this.scoreSingleList(params.musicId);
      });
    },
    scoreSongsList(id) {
      let d = { listId: id };
      sendGetCommentList.call(this, d);
    },
    scoreSingleList(id) {
      let d = { musicId: id };
      sendGetCommentList.call(this, d);
    },
    mixinResetScoreData() {
      this.scoreList = [];
      this.mixinScoreCurrent = 0;
      this.lock = false;
    },
  },
};
function scoreSubmit(e) {
  return new Promise((resolve) => {
    const userInfo = this.$store.state.user.userInfo;
    if (userInfo === null) {
      this.$message.info("请先登录");
      return;
    }
    this.$api
      .saveComment({
        ...e,
        userId: userInfo.id,
        userName: userInfo.nickname,
        userIcon: userInfo.icon,
      })
      .then((res) => {
        // console.log(res);
        if (res.code === 200) {
          resolve();
        }
      });
  });
}
function sendGetCommentList(e) {
  if (this.lock) return;
  this.lock = true;
  this.mixinScoreCurrent++;
  let { mixinScoreSize, mixinScoreCurrent } = this;

  setTimeout(() => {
    this.$api
      .commentList({ ...e, size: mixinScoreSize, current: mixinScoreCurrent })
      .then((resolve) => {
        resolve.data.records.length !== 0 && (this.lock = false);
        this.scoreListTotal = resolve.data.total;
        this.scoreList.push(
          ...resolve.data.records.map((item) => {
            return {
              username: item.userName || "用户" + item.userId,
              fraction: item.score,
              time: item.gmtCreate,
              musicName: item.musicName,
              userIcon: item.userIcon,
            };
          })
        );
      })
      .catch(() => {
        this.lock = false;
      });
  }, 300);
}
