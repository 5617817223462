<template>
  <div>
    <div class="h">{{ data.name }}</div>
    <div class="user" v-if="data.user">
      <img src="../../assets/u.png" />{{ data.user }}
    </div>
    <div class="info">
      <span>{{ songs ? "歌单" : "歌曲" }}类别：{{ data.type }}</span>
      <span>播放量：{{ data.times }}</span>
      <span>发行时间：{{ data.releaseTime | mixinRetainYMD }}</span>
    </div>
    <div class="btns">
      <slot name="btns"></slot>
    </div>
  </div>
</template>
<script>
import { mixinRetainYMD } from "@/mixin/date";
export default {
  mixins: [mixinRetainYMD],
  props: {
    data: Object,
    songs: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
$infoSpanWidth: 267px;
.h {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 48px;
}
.user {
  margin-top: 10px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  img {
    width: 15px;
    margin-right: 10px;
  }
}
.info {
  width: $infoSpanWidth * 2;
  > span {
    display: inline-block;
    width: $infoSpanWidth;
    margin-top: 12px;
  }
}
.btns {
  margin: 42px 0;
  display: flex;
  > * {
    margin-right: 16px;
  }
}
</style>
