<template>
  <div>
    <!--     v-infinite-scroll="load"  infinite-scroll-immediate="true" infinite-scroll-distance="0" style="overflow:auto"-->
    <div class="t" v-if="showTitle">
      用户评价<span>共{{ total }}条评价</span>
    </div>
    <div class="box" v-for="(item, index) in list" :key="index">
      <div class="image">
        <img v-if="item.userIcon" :src="item.userIcon" />
        <img v-else :src="avatar" />
      </div>
      <div class="content">
        <div class="name">{{ item.username }}</div>
        <div class="score">
          <div class="txt">
            <template v-for="(fractionItem, fractionIndex) in fraction">
              <span
                class="fraction-item"
                v-if="item.fraction > fractionIndex"
                :key="fractionIndex"
                ><img :src="fractionItem.y"
              /></span>
              <span class="fraction-item" v-else :key="fractionIndex"
                ><img :src="fractionItem.n"
              /></span>
            </template>
            <span class="fraction-txt">{{
              fraction[item.fraction - 1] && fraction[item.fraction - 1].t
            }}</span>
          </div>
        </div>
        <div class="musicName" v-if="showSource">
          <slot :data="item"></slot>
          <!--          该用户评价了你的作品《{{ item.musicName }}》-->
        </div>
        <div class="time">{{ item.time }}</div>
      </div>
    </div>
    <div class="data-null" v-if="list.length === 0">
      <img src="../../assets/null.png" />
      <p>没有相关评价</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      avatar: "avatar",
    }),
    fraction() {
      return this.$store.state.com.fraction;
    },
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    list: Array,
    showSource: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    // load(){
    //   console.log(this.$refs.scroll &&(this.$refs.scroll.offsetHeight))
    //   this.$emit('load')
    //
    // }
  },
};
</script>
<style>
/*html,body{*/
/*  overflow:auto*/
/*}*/
</style>
<style scoped lang="scss">
.t {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-top: 60px;
  margin-bottom: 40px;
  > span {
    color: #a0a2a6;
    font-size: 16px;
    margin-left: 9px;
  }
}
.box {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.image {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  img {
    width: 100%;
  }
}
.name {
  font-size: 16px;
  font-family: AppleSDGothicNeo-SemiBold, AppleSDGothicNeo;
  font-weight: 600;
  color: #333333;
}
.score {
  margin: 10px 0;
}
.fraction-item {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  img {
    width: 100%;
  }
}
.fraction-txt {
  color: #a0a2a6;
  font-size: 12px;
}
.musicName {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.time {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a0a2a6;
  margin-top: 10px;
}
.data-null {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #696b6f;
  font-size: 18px;
  img {
    width: 300px;
    margin: 20px 0;
  }
}
</style>
